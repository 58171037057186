<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-if="anyDataReady">
            <div
                class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('bookmarks')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'star']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Mein Programm</h1>
                    <span
                        class="text-muted mb-0"
                        v-if="sumUpcomingBroadcasts > 0"
                        >{{ sumUpcomingBroadcasts }} vorgemerkte Sendungen</span
                    >
                    <span
                        class="text-muted mb-0"
                        v-if="sumUpcomingBroadcasts == 0"
                        >Keine Ausstrahlungen für Heute</span
                    >
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>

            <h5 class="page-title mt-3 mb-2">Meine Sender</h5>
            <div class="d-flex flex-wrap align-self-stretch">
                <div
                    v-for="(item, index) in favorites"
                    :key="'a-' + index"
                    class="rounded shadow-sm bg-white m-1 p-1 text-center d-flex flex-column justify-content-center align-items-center"
                    @click="openStationModal(item)"
                >
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                                item.slug +
                                '.png'
                        "
                        :alt="item.name"
                        style="max-width: 60px"
                    />
                    <div
                        style="width: 80px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                    >
                        <strong>{{ item.name }}</strong>
                    </div>
                </div>
                <div
                    class="shadow-sm bg-white m-1 p-1 text-center d-flex flex-column justify-content-center align-items-center"
                    @click="modals.favorites.show = true"
                >
                    <div
                        style="width: 80px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                    >
                        <span class="h4 mb-0 font-weight-bold">
                            <font-awesome-icon
                                :icon="['fas', 'plus']"
                                class="text-primary"
                            />
                            <br />
                        </span>
                    </div>
                </div>
            </div>

            <h5 class="page-title mt-3 mb-2">Meine Sendungen</h5>
            <div
                v-for="(item, index) in series"
                :key="'aa-' + index"
                class="d-flex align-items-center justify-content-between flex-nowrap m-0 my-2 p-2 shadow-sm bg-white"
                @click="openSeriesModal(item)"
            >
                <div class="d-flex align-items-center">
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                                item.station_slug +
                                '.png'
                        "
                        style="max-width: 50px"
                    />
                    <div class="pl-2">
                        <strong class="d-block text-gray-dark">
                            {{ item.series_name }}
                        </strong>
                        <span class="text-muted">{{ item.station_name }}</span>
                    </div>
                </div>
                <div class="pr-2">
                    <font-awesome-icon
                        v-if="!item.muted"
                        :icon="['far', 'bell']"
                        class="text-primary"
                    />
                    <font-awesome-icon
                        v-if="item.muted"
                        :icon="['far', 'bell-slash']"
                        class="text-danger"
                    />
                </div>
            </div>
            <div
                class="shadow-sm bg-white p-1 text-center d-flex flex-column justify-content-center align-items-center"
                style="width: 80px; height: 80px"
                @click="openAddSeriesModal"
            >
                <font-awesome-icon
                    :icon="['fas', 'plus']"
                    class="text-primary h4 mb-0 font-weight-bold"
                />
            </div>
        </div>

        <b-modal
            v-model="modals.favorites.show"
            title=""
            scrollable
            centered
            body-bg-variant="light"
            @ok="saveFavorites()"
            @cancel="reset()"
        >
            <template #modal-header>
                <div>
                    <h5 class="mb-0">
                        Meine Sender -
                        {{ modals.favorites.selected.length }} von
                        {{ stations.length }}
                    </h5>
                </div>
            </template>
            <template #modal-footer="{ ok, cancel }">
                <button class="btn btn-secondary" @click="cancel()">
                    Abbrechen
                </button>
                <button class="btn btn-primary" @click="ok()">
                    Speichern
                </button>
            </template>
            <div
                class="text-center w-100 mb-3"
                @click="modals.favorites.collapsed = false"
                v-if="modals.favorites.collapsed"
            >
                <button type="button" class="btn btn-block btn-outline-primary">
                    Meine Sender anzeigen ({{
                        modals.favorites.selected.length
                    }})<!--<br />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />-->
                </button>
            </div>
            <div v-if="!modals.favorites.collapsed">
                <draggable
                    tag="ul"
                    :list="modals.favorites.selected"
                    class="list-group mt-1"
                    handle=".handle"
                >
                    <li
                        class="list-group-item d-flex align-items-center justify-content-between my-1 p-2 shadow-sm"
                        v-for="(item, index) in modals.favorites.selected"
                        :key="'b-' + index"
                    >
                        <div class="d-flex align-items-center">
                            <div class="text-left">
                                <img
                                    :src="
                                        'images/senderlogos/live_ic_tab_' +
                                            item.slug +
                                            '.png'
                                    "
                                    :alt="item.name"
                                    style="max-width: 40px"
                                />
                            </div>
                            <div class="ml-3">
                                <strong class="mb-0">{{ item.name }}</strong>
                            </div>
                        </div>
                        <div>
                            <span class="px-2 py-1 handle mr-4">
                                <font-awesome-icon
                                    :icon="['fas', 'exchange-alt']"
                                    class="fa-rotate-90"
                                />
                            </span>
                            <span class="px-2 py-1" @click="toggle(item)">
                                <font-awesome-icon
                                    :icon="['fas', 'trash-alt']"
                                />
                            </span>
                        </div>
                    </li>
                </draggable>
                <div
                    class="text-center w-100 mb-3 mt-1"
                    @click="modals.favorites.collapsed = true"
                >
                    <button
                        type="button"
                        class="btn btn-block btn-outline-primary"
                    >
                        Meine Sender ausblenden<!--<br />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />-->
                    </button>
                </div>
            </div>
            <span
                class="text-muted"
                v-if="modals.favorites.selected.length == 0"
            >
                Wähle deine Lieblingssender aus
            </span>
            <hr />
            <div class="input-group my-2">
                <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Sender suchen..."
                    @click="modals.favorites.collapsed = true"
                    @keyup="
                        modals.favorites.search.keyword = $event.target.value
                    "
                    :value="modals.favorites.search.keyword"
                />
                <div
                    class="input-group-append"
                    v-if="modals.favorites.search.keyword.length > 0"
                >
                    <button
                        class="btn btn-secondary"
                        type="button"
                        @click="modals.favorites.search.keyword = ''"
                    >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </div>
            <div
                v-for="(item, index) in stations.filter(item => {
                    return item.name
                        .toLowerCase()
                        .includes(modals.favorites.search.keyword.toLowerCase())
                })"
                :key="'c-' + index"
                class="d-flex shadow-sm bg-white p-2 my-2 text-center align-items-center"
                :class="{
                    'border border-primary border-2': modals.favorites.selected.find(
                        s => s.id == item.id
                    ),
                }"
                @click="toggle(item)"
            >
                <div class="text-left">
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                                item.slug +
                                '.png'
                        "
                        style="max-width: 40px"
                    />
                </div>
                <div class="ml-3">
                    <strong class="mb-0">{{ item.name }}</strong>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="modals.station.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="light"
            @hidden="onStationModalHidden"
        >
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="modals.station.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>

                    <b-dropdown
                        dropleft
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <font-awesome-icon
                                class="text-dark"
                                :icon="['fas', 'ellipsis-v']"
                            />
                        </template>
                        <b-dropdown-item
                            @click="
                                openStreamSourceModal(modals.station.selected)
                            "
                            >Quelle anpassen</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="removeFavorite(modals.station.selected)"
                            >Sender entfernen</b-dropdown-item
                        >
                    </b-dropdown>
                </div>
            </template>
            <div
                class="rounded shadow-sm bg-white mb-4 p-1 text-center d-flex flex-column justify-content-center align-items-center"
            >
                <img
                    :src="
                        'images/senderlogos/live_ic_tab_' +
                            modals.station.selected.slug +
                            '.png'
                    "
                    style="max-width: 100px"
                />

                <div
                    style="width: 80px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                >
                    <strong></strong>
                </div>
            </div>
            <div v-if="modals.station.selected.url == null">
                <p class="text-muted mb-1">
                    Klicke auf "{{ modals.station.selected.name }} suchen" und
                    wähle einen passenden Sender aus
                </p>
                <button type="button" class="btn btn-primary btn-block mb-4">
                    {{ modals.station.selected.name }} suchen
                </button>
            </div>
            <button
                type="button"
                class="btn btn-primary btn-block mb-4"
                v-if="modals.station.selected.url"
            >
                {{ modals.station.selected.name }} starten
            </button>
            <h5 class="page-title my-0">Was läuft jetzt</h5>
            <div
                v-for="(item, index) in flattenedProgram.slice(
                    0,
                    modals.station.slice
                )"
                :key="'d-' + index"
            >
                <broadcast
                    :layout="BROADCAST_LAYOUT_PROGRAM"
                    :item="item"
                    :c="item.c"
                />
            </div>
            <div
                class="text-center w-100 py-1 font-weight-bold"
                @click="modals.station.slice = 100"
                v-if="modals.station.slice == 3"
            >
                Programm ausklappen
            </div>
            <div
                class="text-center w-100 py-1 font-weight-bold"
                @click="modals.station.slice = 3"
                v-if="modals.station.slice == 100"
            >
                Programm einklappen
            </div>
            <h5 class="page-title mb-0 mt-4">Was läuft heute Abend</h5>
            <div v-for="(item, index) in primeTime" :key="'e-' + index">
                <broadcast
                    :layout="BROADCAST_LAYOUT_PROGRAM"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </b-modal>
        <b-modal
            v-model="modals.series.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="white"
            @hidden="onStationModalHidden"
        >
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="modals.series.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                    <b-dropdown
                        dropleft
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <font-awesome-icon
                                class="text-dark"
                                :icon="['fas', 'ellipsis-v']"
                            />
                        </template>
                        <b-dropdown-item
                            @click="toggleMute()"
                            v-if="!activeSeries.muted"
                        >
                            Stumm schalten</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="toggleMute()"
                            v-if="activeSeries.muted"
                        >
                            Stummschaltung aufheben</b-dropdown-item
                        >
                        <b-dropdown-item @click="removeSeries(activeSeries)">
                            Sendung entfernen</b-dropdown-item
                        >
                    </b-dropdown>
                </div>
            </template>
            <div class="d-flex align-items-center py-2">
                <img
                    :src="
                        'images/senderlogos/live_ic_tab_' +
                            activeSeries.station_slug +
                            '.png'
                    "
                    style="max-width: 40px"
                />
                <div class="p-2">
                    <h5 class=" font-weight-bold mb-0">
                        {{ activeSeries.series_name }}
                    </h5>
                    <span class="text-muted">{{
                        activeSeries.station_name
                    }}</span>
                </div>
                {{ activeSeries }}
            </div>
            <button
                v-if="activeSeries.muted"
                class="btn btn-block btn-danger"
                @click="toggleMute()"
            >
                <font-awesome-icon :icon="['far', 'bell-slash']" />
                Stummschaltung aufheben
            </button>

            <h5 class="page-title mb-0 mt-4">Ausstrahlungen</h5>
            <span class="text-muted"
                >Du wirst 5 Minuten vor Sendungsbeginn benachrichtigt</span
            >
            <div v-if="modals.series.loading">
                <b-spinner class="my-4 mx-2" label="Laden..."></b-spinner>
            </div>
            <table class="table table-striped table-sm w-100 my-2" v-else>
                <tbody>
                    <tr
                        v-for="(item, index) in seriesBroadcasts"
                        :key="'zzzk-' + index"
                    >
                        <td class="text-left">
                            {{ dayjs(item.start).format('DD.MM.YYYY - HH:mm') }}
                        </td>
                        <td class="text-left">
                            {{ item.title }}
                        </td>
                        <td class="text-right">
                            <font-awesome-icon
                                :icon="['far', 'bell-slash']"
                                class="text-danger"
                                v-if="activeSeries.muted"
                            />
                            <font-awesome-icon
                                :icon="['far', 'bell']"
                                class="text-primary"
                                v-if="!activeSeries.muted"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <span class="text-muted"
                >Zwischen 00:00 - 06:00 erhälst du keine
                Benachrichtigungen</span
            >
        </b-modal>
        <b-modal
            v-model="modals.add_series.show"
            modal-class="modal-fullscreen"
            scrollable
            body-bg-variant="light"
            @hidden="onAddSeriesModalHidden"
            @ok="saveSeries()"
        >
            <template #modal-header>
                <div>
                    <h5 class="mb-0">
                        Meine Sendungen
                    </h5>
                </div>
            </template>
            <template #modal-footer="{ ok, cancel }">
                <button class="btn btn-secondary" @click="cancel()">
                    Abbrechen
                </button>
                <button class="btn btn-primary" @click="ok()">
                    Speichern
                </button>
            </template>
            <div v-if="!modals.add_series.collapsed">
                <span class="text-muted"
                    >Du wirst benachrichtigt, kurz bevor deine Lieblingssendung
                    im TV läuft</span
                >
                <ul class="list-group mt-1">
                    <li
                        class="list-group-item d-flex align-items-center justify-content-between my-1 p-2 shadow-sm"
                        v-for="(item, index) in modals.add_series.selected"
                        :key="'zzz-' + index"
                    >
                        <div class="d-flex align-items-center">
                            <div class="text-left">
                                <img
                                    :src="
                                        'images/senderlogos/live_ic_tab_' +
                                            item.station_slug +
                                            '.png'
                                    "
                                    style="max-width: 40px"
                                />
                            </div>
                            <div class="ml-3">
                                <strong class="d-block text-gray-dark">
                                    {{ item.series_name }}
                                </strong>
                                <span class="text-muted">{{
                                    item.station_name
                                }}</span>
                            </div>
                        </div>
                        <div>
                            <span class="px-2 py-1" @click="toggleSeries(item)">
                                <font-awesome-icon
                                    :icon="['fas', 'trash-alt']"
                                />
                            </span>
                        </div>
                    </li>
                </ul>
                <div
                    class="text-center w-100 py-1 font-weight-bold"
                    @click="modals.add_series.collapsed = true"
                >
                    <font-awesome-icon :icon="['fas', 'chevron-up']" /> <br />
                    Einklappen
                </div>
            </div>
            <div
                class="text-center w-100 py-1 font-weight-bold"
                @click="modals.add_series.collapsed = false"
                v-if="modals.add_series.collapsed"
            >
                Meine Lieblingssendungen anzeigen ({{
                    modals.add_series.selected.length
                }})<br />
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </div>

            <hr />
            <div class="input-group my-2">
                <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Sendung suchen..."
                    @click="modals.add_series.collapsed = true"
                    @keyup="
                        modals.add_series.search.keyword = $event.target.value
                    "
                    :value="modals.add_series.search.keyword"
                />
                <div
                    class="input-group-append"
                    v-if="modals.add_series.search.keyword.length > 0"
                >
                    <button
                        class="btn btn-secondary"
                        type="button"
                        @click="modals.add_series.search.keyword = ''"
                    >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </div>
            <div v-if="modals.add_series.search.loading">
                <b-spinner class="my-4 mx-2" label="Laden..."></b-spinner>
            </div>
            <div
                class="text-muted"
                v-if="
                    !modals.add_series.search.loading &&
                        modals.add_series.search.keyword.length >= 1
                "
            >
                <span v-if="searchResults.length == 1">
                    Wähle deine Lieblingssendung aus
                </span>
                <span v-if="searchResults.length == 0">
                    Keine Sendung gefunden
                </span>
                <span
                    v-if="
                        searchResults.length >= 2 && searchResults.length < 49
                    "
                >
                    Wähle deine Lieblingssendungen aus
                </span>
            </div>
            <ul class="list-group mt-1">
                <li
                    class="list-group-item d-flex align-items-center justify-content-between my-1 p-2 shadow-sm"
                    :class="{
                        'border border-primary border-2': modals.add_series.selected.find(
                            s => s.series_id == item.series_id
                        ),
                    }"
                    v-for="(item, index) in searchResults"
                    :key="'zzzh-' + index"
                    @click="toggleSeries(item)"
                >
                    <div class="d-flex align-items-center">
                        <div class="text-left">
                            <img
                                :src="
                                    'images/senderlogos/live_ic_tab_' +
                                        item.station_slug +
                                        '.png'
                                "
                                style="max-width: 40px"
                            />
                        </div>
                        <div class="ml-2">
                            <strong class="d-block text-gray-dark">
                                {{ item.series_name }}
                            </strong>
                            <span class="text-muted">{{
                                item.station_name
                            }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </b-modal>
        <b-modal
            v-model="modals.stream_source.show"
            scrollable
            hide-footer
            centered
        >
            <div class="input-group mb-3">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Sender-Url eintippen..."
                    :value="modals.stream_source.selected.url"
                    @keyup="modals.stream_source.dirty = true"
                />
                <div class="input-group-append">
                    <button
                        type="button"
                        class="btn mb-3 btn-success"
                        v-if="modals.stream_source.dirty"
                    >
                        <font-awesome-icon
                            :icon="['far', 'save']"
                            class="text-white"
                        />
                    </button>
                    <button
                        class="btn btn-outline-secondary"
                        type="button"
                        v-else
                    >
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </button>
                </div>
            </div>
            <button type="button" class="btn btn-primary btn-block">
                {{ modals.stream_source.selected.name }} suchen
            </button>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
        draggable,
    },
    data: function() {
        return {
            dayjs,
            dragging: false,
            loading: true,
            modals: {
                stream_source: {
                    show: false,
                    selected: {},
                    dirty: false,
                },
                favorites: {
                    show: false,
                    selected: [],
                    collapsed: true,
                    hide: false,
                    search: {
                        keyword: '',
                        loading: false,
                    },
                },
                station: {
                    show: false,
                    selected: {},
                    slice: 3,
                },
                add_series: {
                    show: false,
                    selected: [],
                    collapsed: true,
                    hide: false,
                    search: {
                        keyword: '',
                        loading: false,
                        request: null,
                    },
                },
                series: {
                    show: false,
                    all: [],
                    selected: {},
                    broadcasts: [],
                    loading: false,
                },
            },
            items: [],
        }
    },
    watch: {
        favorites: {
            deep: true,
            handler() {
                this.modals.favorites.selected = _.cloneDeep(this.favorites)
            },
        },
        series: {
            deep: true,
            handler() {
                this.modals.add_series.selected = _.cloneDeep(this.series)
            },
        },

        'modals.add_series.search.keyword': {
            handler() {
                if (this.modals.add_series.search.loading)
                    this.cancelSearchSeriesRequest()
                if (this.modals.add_series.search.keyword.length) {
                    this.modals.add_series.search.loading = true
                    this.debSearchSeries()
                } else {
                    this.$store.commit('series/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
    },
    computed: {
        sumUpcomingBroadcasts() {
            return _.reduce(
                this.future,
                function(sum, n) {
                    return sum + n.items.length
                },
                0
            )
        },
        ...mapGetters('bookmarks', {
            future: 'groupedByDateFuture',
        }),
        ...mapGetters('program', {
            stations: 'stations',
        }),
        ...mapGetters('program', {
            pDayFromNow: 'pDay',
        }),
        ...mapGetters('series', {
            searchResults: 'search',
        }),
        ...mapGetters('series', {
            series: 'series',
        }),
        ...mapGetters('series', {
            seriesBroadcasts: 'broadcasts',
        }),
        flattenedProgram() {
            console.log('flattenedProgram', this.activeStation)
            if (Object.keys(this.activeStation).length) {
                return this.activeStation.sections
                    .filter(section => section.data.length)
                    .map(item => item.data)
                    .flat()
            }
            return []
        },
        primeTime() {
            return this.flattenedProgram
                .filter(item => {
                    const d = this.dayjs(item.bc_end)
                        .startOf('day')
                        .add(20, 'hours')
                        .add(15, 'minutes')
                    return this.dayjs(item.bc_end).isAfter(d)
                })
                .slice(0, 3)
        },
        activeSeries() {
            if (this.modals.series.selected != null) {
                return this.modals.series.selected
            } else {
                return {}
            }
        },
        activeStation() {
            if (
                this.pDayFromNow.length &&
                this.modals.station.selected != null
            ) {
                const f = this.pDayFromNow.find(
                    item => item.slug == this.modals.station.selected.slug
                )
                if (f !== undefined) {
                    return f
                }
                return {}
            }
            return {}
        },
        anyDataReady() {
            return true
            return this.$store.state.program.init
        },
        favorites: {
            get() {
                return this.$store.state.user.stations
            },
            set(value) {
                //
            },
        },
    },
    methods: {
        removeFavorite(item) {
            const idx = this.modals.favorites.selected.findIndex(
                item2 => item2.id == item.id
            )
            this.modals.favorites.selected.splice(idx, 1)
            this.saveFavorites()
            this.modals.station.show = false
        },
        removeSeries(item) {
            const idx = this.modals.series.all.findIndex(
                item2 => item2.series_id == item.series_id
            )
            this.modals.series.all.splice(idx, 1)
            this.$store.dispatch('series/store', {
                data: {
                    items: this.modals.series.all,
                },
            })
            this.modals.series.show = false
        },
        toggleMute() {
            this.$store
                .dispatch('series/update', {
                    data: {
                        id: this.activeSeries.series_id,
                        update: {
                            muted: !this.activeSeries.muted,
                        },
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        onSearchSeriesKeyUp() {
            if (this.modals.add_series.search.loading) this.cancelRequest()
            if (this.modals.add_series.search.keyword.length >= 2) {
                this.debSearch()
            } else {
                this.searchResults = []
            }
        },
        cancelSearchSeriesRequest() {
            this.modals.add_series.search.request.cancel()
        },
        searchSeries() {
            if (this.modals.add_series.search.keyword.length) {
                const axiosSource = axios.CancelToken.source()
                this.modals.add_series.search.request = {
                    cancel: axiosSource.cancel,
                }
                this.modals.add_series.search.loading = true
                this.$store
                    .dispatch('series/search', {
                        data: {
                            keyword: this.modals.add_series.search.keyword,
                        },
                        options: {
                            cancelToken: axiosSource.token,
                        },
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                    })
                    .then(() => {
                        this.modals.add_series.search.loading = false
                    })
            }
        },
        cc(val) {
            this.$router.push({
                name: val,
                query: {
                    history_back: 1,
                },
            })
        },
        onFavoritesModalOpen() {},
        onFavoritesModalClose() {},
        onSeriesModalOpen() {},
        onSeriesModalHidden() {},
        onStationModalOpen() {},
        onStationModalHidden() {
            this.modals.station.selected = {}
        },
        onAddSeriesModalHidden() {
            this.modals.add_series.selected = []
            this.modals.add_series.search.keyword = ''
            this.modals.add_series.search.loading = false
            this.modals.add_series.search.request = null
        },
        openSeriesModal(item) {
            this.modals.series.loading = true
            this.modals.series.show = true
            this.modals.series.selected = item
            this.modals.series.all = _.cloneDeep(this.series)
            this.$store
                .dispatch('series/broadcasts', {
                    data: {
                        series_id: this.modals.series.selected.series_id,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.modals.series.loading = false
                })
        },
        openAddSeriesModal() {
            this.modals.add_series.show = true
            this.modals.add_series.selected = _.cloneDeep(this.series)
        },
        openStationModal(item) {
            this.modals.station.show = true
            this.modals.station.selected = item
        },
        openStreamSourceModal(item) {
            this.modals.stream_source.show = true
            this.modals.stream_source.selected = item
        },
        toggle(item) {
            let idx = this.modals.favorites.selected.findIndex(
                i => i.id === item.id
            )
            if (idx === -1) {
                this.modals.favorites.selected.push(item)
            } else {
                this.modals.favorites.selected.splice(idx, 1)
            }
        },
        toggleSeries(item) {
            let idx = this.modals.add_series.selected.findIndex(
                i => i.series_id === item.series_id
            )
            if (idx === -1) {
                this.modals.add_series.selected.push(item)
            } else {
                this.modals.add_series.selected.splice(idx, 1)
            }
        },
        saveFavorites() {
            this.$store.dispatch('user/updateStations', {
                data: {
                    items: this.modals.favorites.selected,
                },
            })
        },
        saveSeries() {
            this.$store.dispatch('series/store', {
                data: {
                    items: this.modals.add_series.selected,
                },
            })
        },
        reset() {
            this.modals.favorites.selected = _.cloneDeep(this.favorites)
        },
        startBroadcast(item) {
            const token = this.$store.state.auth.token
            axios
                .post(
                    '/activity/start-stream',
                    {
                        station_slug: item.slug,
                        bc_title: null,
                        bc_end: null,
                        bc_start: null,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then(response => {
                    this.nativeBridge(
                        response.data['fn'],
                        response.data['params']
                    )
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
    },
    mounted() {
        this.modals.favorites.selected = _.cloneDeep(this.favorites)
        this.modals.add_series.selected = _.cloneDeep(this.series)
        this.debSearchSeries = _.debounce(this.searchSeries, 250)
    },
}
</script>
