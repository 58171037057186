const state = {
    token: localStorage.getItem('api_token') || null,
}
const getters = {}
const actions = {}
const mutations = {}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
