var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.anyDataReady)?_c('div',[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}}),_c('b-skeleton',{attrs:{"width":"45%"}})],1):_c('div',[_c('div',{staticClass:"input-group my-2"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","autocomplete":"off","placeholder":"Durchsuchen..."},domProps:{"value":_vm.search.keyword},on:{"keyup":function($event){_vm.search.keyword = $event.target.value}}}),(_vm.search.keyword.length > 0)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.search.keyword = ''}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'times']}})],1)]):_vm._e()]),(_vm.search.loading)?_c('div',[_c('b-spinner',{staticClass:"my-4 mx-2",attrs:{"label":"Laden..."}})],1):_vm._e(),(!_vm.search.loading && _vm.search.keyword.length >= 1)?_c('div',[(_vm.search.keyword.length >= 1)?_c('div',{staticClass:"text-left"},[(_vm.searchResults.length == 0)?_c('span',[_vm._v("Keine Ergebnisse")]):_vm._e(),(_vm.searchResults.length == 1)?_c('span',[_vm._v("1 Ergebniss")]):_vm._e(),(
                        _vm.searchResults.length >= 2 &&
                            _vm.searchResults.length < 49
                    )?_c('span',[_vm._v(_vm._s(_vm.searchResults.length)+" Ergebnisse")]):_vm._e(),(_vm.searchResults.length == 50)?_c('span',[_vm._v("über "+_vm._s(_vm.searchResults.length)+" Ergebnisse")]):_vm._e()]):_vm._e(),_vm._l((_vm.searchResults),function(item,index){return _c('div',{key:'s-' + index},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_SEARCH,"item":item}})],1)})],2):_vm._e(),(_vm.search.keyword.length < 1 && !_vm.$route.query.only_search)?_c('div',[_c('div',{staticClass:"days d-flex py-2"},_vm._l((_vm.days),function(item,index){return _c('div',{key:'date-' + index,staticClass:"shadow-sm py-1 px-3 mx-1 bg-white text-center",class:{
                        'text-primary': item.isToday,
                        'border border-primary':
                            item.date.format('YYYY-MM-DD') == _vm.dateQuery ||
                            (_vm.dateQuery === null && index == 0),
                    },on:{"click":function($event){_vm.navigateDate(item.date.format('YYYY-MM-DD'))}}},[_c('small',{staticClass:"text-muted"},[_c('span',{class:{
                                'text-danger':
                                    item.date.format('dd') == 'Sa' ||
                                    item.date.format('dd') == 'So',
                            }},[_vm._v(_vm._s(item.date.format('dd')))])]),_c('br'),_c('h4',{staticClass:"m-0 font-weight-bolder"},[_vm._v(" "+_vm._s(item.date.format('D'))+" ")]),(item.isToday)?_c('small',[_vm._v("heute")]):_vm._e()])}),0),_c('div',{staticClass:"header bg-light",attrs:{"id":"myHeader"}},[_c('div',{staticClass:"stations d-flex py-1"},_vm._l((_vm.pDayFromNow),function(item,index){return _c('div',{key:'station-' + index,staticClass:"shadow-sm p-1 mx-1 bg-white d-flex justify-content-center",class:{
                            'border border-primary':
                                item.slug == _vm.stationQuery ||
                                (_vm.stationQuery === null && index == 0),
                        },on:{"click":function($event){return _vm.navigateStation(item)}}},[_c('img',{staticStyle:{"max-width":"60px"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                                    item.slug +
                                    '.png'}})])}),0)]),_c('div',{staticClass:"content"},[(_vm.loading)?_c('div',[_c('b-spinner',{staticClass:"m-2",attrs:{"label":"Laden..."}})],1):_vm._l((_vm.activeStation.sections),function(item,index){return _c('div',{key:'range-' + index},[(item.data.length)?_c('h5',{staticClass:"ml-1 mb-2 mt-4 font-weight-bolder"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),_vm._l((item.data),function(item2,index2){return _c('div',{key:'p-' + index2},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_PROGRAM,"item":item2,"c":item2.c}})],1)})],2)})],2)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }