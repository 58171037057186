var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-between flex-nowrap m-0 my-2 p-2 shadow-sm bg-white",attrs:{"id":_vm.c},on:{"click":function($event){return _vm.handleBroadcastClick(_vm.item)}}},[(_vm.layout == _vm.BROADCAST_LAYOUT_SEARCH + 1)?_c('div',{staticClass:"flex-fill",staticStyle:{"font-size":"115%"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticStyle:{"max-width":"90px"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                        _vm.item.station_slug +
                        '.png'}}),_c('div',{staticClass:"ml-2 flex-fill"},[_c('small',{staticClass:"d-block text-gray-dark"},[_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.item.bc_start).format('HH:mm'))+" - "+_vm._s(_vm.dayjs(_vm.item.bc_end).format('HH:mm'))+" ")]),_c('span',{staticClass:"mx-1 text-muted"},[_vm._v("|")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.item.station_name)+" ")])]),_c('strong',{staticClass:"d-block text-gray-dark"},[_vm._v(_vm._s(_vm.item.bc_title))]),_c('div',{staticClass:"progress",staticStyle:{"height":"3px"}},[_c('div',{staticClass:"progress-bar",style:({
                            width: _vm.remainingMinutesProgress(
                                _vm.item.bc_start,
                                _vm.item.bc_end
                            ),
                        })})]),_c('small',[_vm._v("läuft "+_vm._s(_vm.remainingMinutes(_vm.item.bc_end)))])])])]):_vm._e(),(_vm.layout == _vm.BROADCAST_LAYOUT_LIVE)?_c('div',{staticClass:"flex-fill",staticStyle:{"font-size":"115%"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('img',{staticStyle:{"max-width":"90px"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                            _vm.item.station_slug +
                            '.png'}})]),_c('div',{staticClass:"ml-2 flex-fill"},[_c('small',{staticClass:"d-block text-gray-dark"},[_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.item.bc_start).format('HH:mm'))+" - "+_vm._s(_vm.dayjs(_vm.item.bc_end).format('HH:mm'))+" ")]),_c('span',{staticClass:"mx-1 text-muted"},[_vm._v("|")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.item.station_name)+" ")])]),_c('strong',{staticClass:"d-block text-gray-dark"},[_vm._v(_vm._s(_vm.item.bc_title))]),(_vm.item.running)?_c('div',[_c('div',{staticClass:"progress",staticStyle:{"height":"3px"}},[_c('div',{staticClass:"progress-bar",style:({
                                width: _vm.remainingMinutesProgress(
                                    _vm.item.bc_start,
                                    _vm.item.bc_end
                                ),
                            })})]),_c('small',[_vm._v("läuft "+_vm._s(_vm.remainingMinutes(_vm.item.bc_end)))])]):_vm._e(),(!_vm.item.running && !_vm.item.finished)?_c('div',[_c('small',[_vm._v("beginnt "+_vm._s(_vm.startIn(_vm.item.bc_start)))])]):_vm._e(),(_vm.item.finished)?_c('div',[_c('small',[_vm._v("Sendung ist beendet")])]):_vm._e()])])]):_vm._e(),(_vm.layout == _vm.BROADCAST_LAYOUT_PROGRAM)?_c('div',{staticClass:"flex-fill",staticStyle:{"font-size":"110%"}},[_c('small',{staticClass:"d-block"},[_c('span',{class:{
                    'text-primary': _vm.isPrimeTime,
                    'text-muted': !_vm.isPrimeTime,
                }},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.item.bc_start).format('HH:mm'))+" - "+_vm._s(_vm.dayjs(_vm.item.bc_end).format('HH:mm'))+" ")]),_c('div',{staticClass:"ml-1 text-muted",staticStyle:{"display":"inline-block"}},[(
                        _vm.item.series !== null &&
                            !_vm.item.series.whitelist.includes(
                                _vm.dayjs(_vm.item.bc_start).format('HH:mm')
                            )
                    )?_c('font-awesome-icon',{attrs:{"icon":['far', 'bell-slash']}}):_vm._e(),(
                        _vm.item.series !== null &&
                            _vm.item.series.whitelist.includes(
                                _vm.dayjs(_vm.item.bc_start).format('HH:mm')
                            )
                    )?_c('font-awesome-icon',{attrs:{"icon":['far', 'bell']}}):_vm._e()],1),(_vm.item.running)?_c('span',{staticClass:"badge badge-primary text-white ml-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'dot-circle']}}),_vm._v(" LIVE ")],1):_vm._e(),(_vm.isPrimeTime)?_c('span',{staticClass:"badge badge-primary text-white ml-2"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'medal']}}),_vm._v(" PRIMETIME ")],1):_vm._e()]),_c('strong',{staticClass:"d-block text-gray-dark"},[_vm._v(_vm._s(_vm.item.bc_title))]),(_vm.isPrimeTime && _vm.item.series !== null)?_c('div',{staticClass:"small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['far', 'star']}}),_c('span',{staticClass:"ml-2"},[_vm._v("456 Bewertungen")])],1):_vm._e(),(_vm.item.running)?_c('div',[_c('div',{staticClass:"progress",staticStyle:{"height":"3px"}},[_c('div',{staticClass:"progress-bar",style:({
                        width: _vm.remainingMinutesProgress(
                            _vm.item.bc_start,
                            _vm.item.bc_end
                        ),
                    })})]),_c('small',[_vm._v("läuft "+_vm._s(_vm.remainingMinutes(_vm.item.bc_end)))])]):_vm._e()]):_vm._e(),(_vm.layout == _vm.BROADCAST_LAYOUT_SEARCH)?_c('div',{staticClass:"d-flex",staticStyle:{"font-size":"115%"}},[_vm._v(" "+_vm._s(_vm.isPrimeTime)+" "),_c('div',[_c('img',{staticStyle:{"max-width":"80px","border":"1px #eee solid"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                        _vm.item.station_slug +
                        '.png',"alt":_vm.item.station_name}})]),_c('div',{staticClass:"ml-2"},[(_vm.dayjs().isBetween(_vm.item.bc_start, _vm.item.bc_end))?_c('small',{staticClass:"text-danger d-block"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'dot-circle']}}),_vm._v("Jetzt LIVE ")],1):_vm._e(),_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.item.bc_start).format('dd, DD.MM.YYYY'))+" ")]),_c('small',{staticClass:"d-block text-gray-dark"},[_vm._v(" "+_vm._s(_vm.item.station_name)+" "),_c('span',{staticClass:"text-primary mx-1"},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.item.bc_start).format('HH:mm'))+" - "+_vm._s(_vm.dayjs(_vm.item.bc_end).format('HH:mm'))+" ")])]),_c('strong',{staticClass:"d-block text-gray-dark"},[_vm._v(" "+_vm._s(_vm.item.bc_title)+" ")])])]):_vm._e(),(_vm.layout == 10000000000000000000000)?_c('div',{staticStyle:{"font-size":"115%"}},[(_vm.dayjs().isBetween(_vm.item.bc_start, _vm.item.bc_end))?_c('small',{staticClass:"text-danger d-block"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'dot-circle']}}),_vm._v("Jetzt LIVE ")],1):_vm._e(),_c('small',{staticClass:"d-block text-gray-dark"},[_vm._v(" "+_vm._s(_vm.item.station)+" "),_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.item.bc_start).format('HH:mm'))+" - "+_vm._s(_vm.dayjs(_vm.item.bc_end).format('HH:mm'))+" ")])]),_c('strong',{staticClass:"d-block text-gray-dark"},[_vm._v(_vm._s(_vm.item.bc_title))])]):_vm._e(),(
            _vm.item.running ||
                (_vm.layout == _vm.BROADCAST_LAYOUT_BOOKMARK &&
                    _vm.startInSeconds(_vm.item.bc_start) < 600)
        )?_c('div',{staticClass:"ml-2 p-1"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1):_vm._e(),(
            !_vm.item.finished &&
                !_vm.item.running &&
                !_vm.item.loading &&
                _vm.item.series !== null
        )?_c('div',{staticClass:"ml-2 p-1"},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":['fas', 'heart']}})],1):_vm._e(),(
            !_vm.item.finished &&
                !_vm.item.running &&
                !_vm.item.loading &&
                _vm.item.series === null
        )?_c('div',{staticClass:"ml-2 p-1"},[_c('font-awesome-icon',{staticClass:"text-dark",attrs:{"icon":['far', 'heart']}})],1):_vm._e(),(!_vm.item.running && _vm.item.loading)?_c('div',{staticClass:"ml-2 p-1"},[_c('b-spinner',{staticClass:"text-muted",attrs:{"small":"","label":"Laden..."}})],1):_vm._e(),_c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","modal-class":"modal-fullscreen","body-class":"d-flex align-items-center justify-content-center","hide-header":"","hide-footer":"","no-fade":""},on:{"hidden":function($event){return _vm.adModalHidden()},"shown":function($event){return _vm.adCountDownTimer()}},model:{value:(_vm.ad.modal.show),callback:function ($$v) {_vm.$set(_vm.ad.modal, "show", $$v)},expression:"ad.modal.show"}},[_c('div',{staticClass:"text-center"},[_c('font-awesome-icon',{staticStyle:{"font-size":"50px"},attrs:{"icon":['fas', 'ad']}}),_c('h2',[_vm._v("Werbung beginnt in")]),_c('h2',[_vm._v(_vm._s(_vm.ad.countDown)+"s")])],1)]),_c('b-modal',{attrs:{"scrollable":"","hide-footer":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"p-2 h5 mb-0",on:{"click":function($event){_vm.broadcast.modal.show = false}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-left']}})],1)])]},proxy:true}]),model:{value:(_vm.broadcast.modal.show),callback:function ($$v) {_vm.$set(_vm.broadcast.modal, "show", $$v)},expression:"broadcast.modal.show"}},[(!_vm.seriesExists)?_c('div',[_vm._v(" Es ist ein Fehler aufgetreten. Bitte versuche es später erneut. ")]):_c('div',[(!_vm.broadcast.modal.loading)?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticStyle:{"max-width":"75px"},attrs:{"src":'images/senderlogos/live_ic_tab_' +
                                _vm.selectedBroadcast.station_slug +
                                '.png'}}),_c('div',{staticClass:"p-2"},[_c('div',[_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('font-awesome-icon',{attrs:{"icon":['far', 'star']}}),_c('span',{staticClass:"ml-2"},[_vm._v("456 Bewertungen")])],1),_c('h5',{staticClass:" font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.activeSeries.series_name)+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.selectedBroadcast.station_name))])])]),(!_vm.isUserSeries)?_c('button',{staticClass:"btn btn-outline-primary btn-block mb-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.pushSeries()}}},[_vm._v(" Sendung merken ")]):_vm._e(),(_vm.isUserSeries)?_c('div',[_c('div',{staticClass:"text-primary text-center font-weight-bold"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}}),_vm._v(" Sendung wurde gemerkt ")],1),_c('hr'),_c('span',{staticClass:"text-muted"},[_vm._v(" Du wirst zu folgenden Zeiten Benachrichtigt. Du kannst du Auswahl anpassen. ")]),_c('div',{staticClass:"mt-2"},_vm._l((_vm.activeSeries.time_groups),function(item,index){return _c('div',{key:'aaa-' + index,staticClass:"d-inline"},[(
                                    _vm.activeUserSeries.whitelist.includes(
                                        item
                                    )
                                )?_c('button',{staticClass:"btn btn-sm btn-primary m-1",on:{"click":function($event){return _vm.popUserSeriesWhitelist(item)}}},[_vm._v(" "+_vm._s(item)+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'bell']}})],1):_vm._e(),(
                                    !_vm.activeUserSeries.whitelist.includes(
                                        item
                                    )
                                )?_c('button',{staticClass:"btn btn-sm btn-outline-dark m-1",on:{"click":function($event){return _vm.pushUserSeriesWhitelist(item)}}},[_vm._v(" "+_vm._s(item)+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'bell-slash']}})],1):_vm._e()])}),0),_c('hr'),_c('h5',{staticClass:"page-title mb-0"},[_vm._v("Ausstrahlungen")]),_c('span',{staticClass:"text-muted"},[_vm._v("Du wirst 5 Minuten vor Sendungsbeginn benachrichtigt")]),_c('table',{staticClass:"table table-striped table-sm w-100 my-2"},[_c('tbody',_vm._l((_vm.activeSeries.broadcasts),function(item,index){return _c('tr',{key:'zzzk-' + index},[_c('td',{staticClass:"text-left",staticStyle:{"width":"85px"}},[_vm._v(" "+_vm._s(_vm.dayjs(item.start).format( 'DD.MM.YYYY' ))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.dayjs(item.start).format('HH:mm'))+" ")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"30px"}},[(
                                            _vm.activeUserSeries.whitelist.includes(
                                                _vm.dayjs(item.start).format(
                                                    'HH:mm'
                                                )
                                            )
                                        )?_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":['far', 'bell']}}):_vm._e(),(
                                            !_vm.activeUserSeries.whitelist.includes(
                                                _vm.dayjs(item.start).format(
                                                    'HH:mm'
                                                )
                                            )
                                        )?_c('font-awesome-icon',{staticClass:"text-dark",attrs:{"icon":['far', 'bell-slash']}}):_vm._e()],1)])}),0)]),_c('button',{staticClass:"btn btn-outline-dark btn-block mt-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.popSeries()}}},[_vm._v(" Dieser Sendung nicht mehr folgen ")])]):_vm._e()]):_c('div',[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}}),_c('b-skeleton',{attrs:{"width":"70%"}}),_c('b-skeleton',{attrs:{"width":"45%"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }