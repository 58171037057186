<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-if="anyDataReady">
            <h5 class="font-weight-bold mt-0 mb-2">Jetzt LIVE</h5>
            <div v-for="(item, index) in pNow" :key="index">
                <broadcast
                    :layout="BROADCAST_LAYOUT_LIVE"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {}
    },
    computed: {
        ...mapGetters('program', {
            pNow: 'pNow',
        }),
        anyDataReady() {
            return this.$store.state.program.init
        },
    },
    methods: {},
    created() {},
}
</script>
