<template>
    <div
        class="d-flex align-items-center justify-content-between flex-nowrap m-0 my-2 p-2 shadow-sm bg-white"
        :id="c"
        @click="handleBroadcastClick(item)"
    >
        <div
            class="flex-fill"
            style="font-size: 115%"
            v-if="layout == BROADCAST_LAYOUT_SEARCH + 1"
        >
            <div class="d-flex align-items-center">
                <img
                    :src="
                        'images/senderlogos/live_ic_tab_' +
                            item.station_slug +
                            '.png'
                    "
                    style="max-width: 90px;"
                />
                <div class="ml-2 flex-fill">
                    <small class="d-block text-gray-dark">
                        <span class="text-primary">
                            {{ dayjs(item.bc_start).format('HH:mm') }} -
                            {{ dayjs(item.bc_end).format('HH:mm') }}
                        </span>
                        <span class="mx-1 text-muted">|</span>
                        <span class="text-muted">
                            {{ item.station_name }}
                        </span>
                    </small>
                    <strong class="d-block text-gray-dark">{{
                        item.bc_title
                    }}</strong>
                    <div class="progress" style="height: 3px">
                        <div
                            class="progress-bar"
                            :style="{
                                width: remainingMinutesProgress(
                                    item.bc_start,
                                    item.bc_end
                                ),
                            }"
                        ></div>
                    </div>
                    <small>läuft {{ remainingMinutes(item.bc_end) }}</small>
                </div>
            </div>
        </div>
        <div
            class="flex-fill"
            style="font-size: 115%"
            v-if="layout == BROADCAST_LAYOUT_LIVE"
        >
            <div class="d-flex align-items-center">
                <div>
                    <img
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                                item.station_slug +
                                '.png'
                        "
                        style="max-width: 90px;"
                    />
                </div>
                <div class="ml-2 flex-fill">
                    <small class="d-block text-gray-dark">
                        <span class="text-primary">
                            {{ dayjs(item.bc_start).format('HH:mm') }} -
                            {{ dayjs(item.bc_end).format('HH:mm') }}
                        </span>
                        <span class="mx-1 text-muted">|</span>
                        <span class="text-muted">
                            {{ item.station_name }}
                        </span>
                    </small>
                    <strong class="d-block text-gray-dark">{{
                        item.bc_title
                    }}</strong>
                    <div v-if="item.running">
                        <div class="progress" style="height: 3px">
                            <div
                                class="progress-bar"
                                :style="{
                                    width: remainingMinutesProgress(
                                        item.bc_start,
                                        item.bc_end
                                    ),
                                }"
                            ></div>
                        </div>
                        <small>läuft {{ remainingMinutes(item.bc_end) }}</small>
                    </div>
                    <div v-if="!item.running && !item.finished">
                        <small>beginnt {{ startIn(item.bc_start) }}</small>
                    </div>
                    <div v-if="item.finished">
                        <small>Sendung ist beendet</small>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex-fill"
            style="font-size: 110%"
            v-if="layout == BROADCAST_LAYOUT_PROGRAM"
        >
            <small class="d-block">
                <span
                    :class="{
                        'text-primary': isPrimeTime,
                        'text-muted': !isPrimeTime,
                    }"
                >
                    {{ dayjs(item.bc_start).format('HH:mm') }} -
                    {{ dayjs(item.bc_end).format('HH:mm') }}
                </span>
                <div style="display: inline-block;" class="ml-1 text-muted">
                    <font-awesome-icon
                        :icon="['far', 'bell-slash']"
                        v-if="
                            item.series !== null &&
                                !item.series.whitelist.includes(
                                    dayjs(item.bc_start).format('HH:mm')
                                )
                        "
                    />
                    <font-awesome-icon
                        :icon="['far', 'bell']"
                        v-if="
                            item.series !== null &&
                                item.series.whitelist.includes(
                                    dayjs(item.bc_start).format('HH:mm')
                                )
                        "
                    />
                </div>
                <span
                    class="badge badge-primary text-white ml-2"
                    v-if="item.running"
                >
                    <font-awesome-icon :icon="['fas', 'dot-circle']" />
                    LIVE
                </span>
                <span
                    class="badge badge-primary text-white ml-2"
                    v-if="isPrimeTime"
                >
                    <font-awesome-icon :icon="['fas', 'medal']" />
                    PRIMETIME
                </span>
            </small>
            <strong class="d-block text-gray-dark">{{ item.bc_title }}</strong>
            <div class="small" v-if="isPrimeTime && item.series !== null">
                <font-awesome-icon :icon="['fas', 'star']" />
                <font-awesome-icon :icon="['fas', 'star']" />
                <font-awesome-icon :icon="['fas', 'star']" />
                <font-awesome-icon :icon="['fas', 'star']" />
                <font-awesome-icon :icon="['far', 'star']" />

                <span class="ml-2">456 Bewertungen</span>
            </div>
            <div v-if="item.running">
                <div class="progress" style="height: 3px">
                    <div
                        class="progress-bar"
                        :style="{
                            width: remainingMinutesProgress(
                                item.bc_start,
                                item.bc_end
                            ),
                        }"
                    ></div>
                </div>
                <small>läuft {{ remainingMinutes(item.bc_end) }}</small>
            </div>
        </div>

        <div
            class="d-flex"
            style="font-size: 115%"
            v-if="layout == BROADCAST_LAYOUT_SEARCH"
        >
            {{ isPrimeTime }}
            <div>
                <img
                    :src="
                        'images/senderlogos/live_ic_tab_' +
                            item.station_slug +
                            '.png'
                    "
                    :alt="item.station_name"
                    style="max-width: 80px; border: 1px #eee solid"
                />
            </div>
            <div class="ml-2">
                <small
                    class="text-danger d-block"
                    v-if="dayjs().isBetween(item.bc_start, item.bc_end)"
                >
                    <font-awesome-icon
                        :icon="['fas', 'dot-circle']"
                        class="mr-1"
                    />Jetzt LIVE
                </small>
                <small class="font-weight-bold">
                    {{ dayjs(item.bc_start).format('dd, DD.MM.YYYY') }}
                </small>
                <small class="d-block text-gray-dark">
                    {{ item.station_name }}
                    <span class="text-primary mx-1">
                        {{ dayjs(item.bc_start).format('HH:mm') }} -
                        {{ dayjs(item.bc_end).format('HH:mm') }}
                    </span>
                </small>
                <strong class="d-block text-gray-dark">
                    {{ item.bc_title }}
                </strong>
            </div>
        </div>

        <div
            class=""
            style="font-size: 115%"
            v-if="layout == 10000000000000000000000"
        >
            <small
                class="text-danger d-block"
                v-if="dayjs().isBetween(item.bc_start, item.bc_end)"
            >
                <font-awesome-icon
                    :icon="['fas', 'dot-circle']"
                    class="mr-1"
                />Jetzt LIVE
            </small>
            <small class="d-block text-gray-dark">
                {{ item.station }}
                <span class="text-primary">
                    {{ dayjs(item.bc_start).format('HH:mm') }} -
                    {{ dayjs(item.bc_end).format('HH:mm') }}
                </span>
            </small>
            <strong class="d-block text-gray-dark">{{ item.bc_title }}</strong>
        </div>

        <div
            class="ml-2 p-1"
            v-if="
                item.running ||
                    (layout == BROADCAST_LAYOUT_BOOKMARK &&
                        startInSeconds(item.bc_start) < 600)
            "
        >
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>
        <div
            class="ml-2 p-1"
            v-if="
                !item.finished &&
                    !item.running &&
                    !item.loading &&
                    item.series !== null
            "
        >
            <font-awesome-icon :icon="['fas', 'heart']" class="text-primary" />
        </div>
        <div
            class="ml-2 p-1"
            v-if="
                !item.finished &&
                    !item.running &&
                    !item.loading &&
                    item.series === null
            "
        >
            <font-awesome-icon :icon="['far', 'heart']" class="text-dark" />
        </div>
        <div class="ml-2 p-1" v-if="!item.running && item.loading">
            <b-spinner small class="text-muted" label="Laden..."></b-spinner>
        </div>

        <b-modal
            v-model="ad.modal.show"
            @hidden="adModalHidden()"
            @shown="adCountDownTimer()"
            no-close-on-backdrop
            centered
            modal-class="modal-fullscreen"
            body-class="d-flex align-items-center justify-content-center"
            hide-header
            hide-footer
            no-fade
        >
            <div class="text-center">
                <font-awesome-icon
                    style="font-size: 50px"
                    :icon="['fas', 'ad']"
                />
                <h2>Werbung beginnt in</h2>
                <h2>{{ ad.countDown }}s</h2>
            </div>
        </b-modal>
        <b-modal v-model="broadcast.modal.show" scrollable hide-footer centered>
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="broadcast.modal.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            <div v-if="!seriesExists">
                Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.
            </div>
            <div v-else>
                <div v-if="!broadcast.modal.loading">
                    <div class="d-flex align-items-center">
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                    selectedBroadcast.station_slug +
                                    '.png'
                            "
                            style="max-width: 75px"
                        />
                        <div class="p-2">
                            <div>
                                <font-awesome-icon :icon="['fas', 'star']" />
                                <font-awesome-icon :icon="['fas', 'star']" />
                                <font-awesome-icon :icon="['fas', 'star']" />
                                <font-awesome-icon :icon="['fas', 'star']" />
                                <font-awesome-icon :icon="['far', 'star']" />

                                <span class="ml-2">456 Bewertungen</span>
                            </div>
                            <h5 class=" font-weight-bold mb-0">
                                {{ activeSeries.series_name }}
                            </h5>
                            <span class="text-muted">{{
                                selectedBroadcast.station_name
                            }}</span>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-block mb-2"
                        v-if="!isUserSeries"
                        @click="pushSeries()"
                    >
                        Sendung merken
                    </button>
                    <div v-if="isUserSeries">
                        <div class="text-primary text-center font-weight-bold">
                            <font-awesome-icon :icon="['fas', 'check']" />
                            Sendung wurde gemerkt
                        </div>
                        <hr />
                        <span class="text-muted">
                            Du wirst zu folgenden Zeiten Benachrichtigt. Du
                            kannst du Auswahl anpassen.
                        </span>
                        <div class="mt-2">
                            <div
                                class="d-inline"
                                v-for="(item,
                                index) in activeSeries.time_groups"
                                :key="'aaa-' + index"
                            >
                                <button
                                    v-if="
                                        activeUserSeries.whitelist.includes(
                                            item
                                        )
                                    "
                                    class="btn btn-sm btn-primary m-1"
                                    @click="popUserSeriesWhitelist(item)"
                                >
                                    {{ item }}
                                    <font-awesome-icon
                                        :icon="['far', 'bell']"
                                    />
                                </button>
                                <button
                                    v-if="
                                        !activeUserSeries.whitelist.includes(
                                            item
                                        )
                                    "
                                    class="btn btn-sm btn-outline-dark m-1"
                                    @click="pushUserSeriesWhitelist(item)"
                                >
                                    {{ item }}
                                    <font-awesome-icon
                                        :icon="['far', 'bell-slash']"
                                    />
                                </button>
                            </div>
                        </div>
                        <hr />
                        <h5 class="page-title mb-0">Ausstrahlungen</h5>
                        <span class="text-muted"
                            >Du wirst 5 Minuten vor Sendungsbeginn
                            benachrichtigt</span
                        >
                        <table class="table table-striped table-sm w-100 my-2">
                            <tbody>
                                <tr
                                    v-for="(item,
                                    index) in activeSeries.broadcasts"
                                    :key="'zzzk-' + index"
                                >
                                    <td class="text-left" style="width: 85px">
                                        {{
                                            dayjs(item.start).format(
                                                'DD.MM.YYYY'
                                            )
                                        }}
                                    </td>
                                    <!--<td class="text-left" style="width: 50px">-->
                                    <td class="text-left">
                                        {{ dayjs(item.start).format('HH:mm') }}
                                    </td>
                                    <!--
                                    <td class="text-left">
                                        {{ item.title }}
                                    </td>
                                    -->
                                    <td class="text-center" style="width: 30px">
                                        <font-awesome-icon
                                            :icon="['far', 'bell']"
                                            class="text-primary"
                                            v-if="
                                                activeUserSeries.whitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                        <font-awesome-icon
                                            :icon="['far', 'bell-slash']"
                                            class="text-dark"
                                            v-if="
                                                !activeUserSeries.whitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button
                            type="button"
                            class="btn btn-outline-dark btn-block mt-2"
                            @click="popSeries()"
                        >
                            Dieser Sendung nicht mehr folgen
                        </button>
                    </div>
                </div>
                <div v-else>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="45%"></b-skeleton>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
const getCountdown = start => {
    const m = Math.ceil(dayjs(start).diff(dayjs(), 'minutes', true))

    const MINUTE = 1
    const HOUR = 60
    const DAY = HOUR * 24

    const days = Math.floor(m / DAY)
    const hDiff = m % DAY
    const hours = Math.floor(hDiff / HOUR)
    const mDiff = hDiff % HOUR
    const minutes = Math.floor(mDiff / MINUTE)

    return [days, hours, minutes]
}
export default {
    name: 'Broadcast',
    props: ['item', 'layout', 'c'],
    data: function() {
        return {
            dayjs,
            ad: {
                modal: {
                    show: false,
                },
                countDown: 3,
                response: {},
            },
            broadcast: {
                modal: {
                    show: false,
                    loading: false,
                },
                selected: {},
            },
        }
    },
    computed: {
        ...mapGetters('series', {
            userSeries: 'series',
        }),
        ...mapGetters('series', {
            activeSeries: 'first',
        }),
        selectedBroadcast() {
            return this.broadcast.selected
        },
        activeUserSeries() {
            const f = this.userSeries.find(
                item => item.series_id == this.selectedBroadcast.series_id
            )
            if (f !== undefined) {
                return f
            }
            return {}
        },
        isUserSeries() {
            if (Object.keys(this.activeUserSeries).length) {
                return true
            }
            return false
        },
        isPrimeTime() {
            return dayjs(this.item.bc_start)
                .startOf('day')
                .add(20, 'hours')
                .add(15, 'minutes')
                .isSame(this.item.bc_start)
        },
        seriesExists() {
            return this.selectedBroadcast.series_id > 0
        },
    },
    methods: {
        syncSeries(items) {
            this.$store.dispatch('series/sync', {
                data: {
                    items,
                },
            })
        },
        updateSeriesWhitelist(items) {
            this.$store
                .dispatch('series/update', {
                    data: {
                        id: this.activeSeries.series_id,
                        update: {
                            whitelist: JSON.stringify(items),
                        },
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                    this.nativeBridge('showToast', 'Änderung gespeichert')
                })
        },
        pushUserSeriesWhitelist(item) {
            let temp = _.cloneDeep(this.activeUserSeries.whitelist)
            temp.push(item)
            this.updateSeriesWhitelist(temp)
        },
        popUserSeriesWhitelist(item) {
            let temp = _.cloneDeep(this.activeUserSeries.whitelist)
            const idx = temp.indexOf(item)
            if (idx > -1) {
                temp.splice(idx, 1)
                this.updateSeriesWhitelist(temp)
            }
        },
        pushSeries() {
            let temp = _.cloneDeep(this.userSeries)
            temp.push({ series_id: this.selectedBroadcast.series_id })
            this.syncSeries(temp)
        },
        popSeries() {
            let temp = _.cloneDeep(this.userSeries)
            const idx = temp.findIndex(
                item2 => item2.series_id == this.selectedBroadcast.series_id
            )
            temp.splice(idx, 1)
            this.syncSeries(temp)
            this.broadcast.modal.show = false
        },
        openBroadcastModal(item) {
            this.broadcast.selected = item
            this.broadcast.modal.show = true

            if (this.selectedBroadcast.series_id > 0) {
                this.broadcast.modal.loading = true
                this.$store
                    .dispatch('series/show', {
                        data: {
                            series_id: this.selectedBroadcast.series_id,
                        },
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                    })
                    .then(() => {
                        this.broadcast.modal.loading = false
                    })
            }
        },
        adModalHidden() {
            this.nativeBridge(
                this.ad.response.data['fn'],
                this.ad.response.data['params']
            )
        },
        adCountDownTimer() {
            if (this.ad.countDown > 0) {
                setTimeout(() => {
                    this.ad.countDown -= 1
                    this.adCountDownTimer()
                }, 1000)
            } else {
                this.ad.modal.show = false
                setTimeout(() => {
                    this.ad.countDown = 3
                }, 500)
            }
        },
        handleBroadcastClick(item) {
            if (
                item.running ||
                (this.layout == this.BROADCAST_LAYOUT_BOOKMARK &&
                    this.startInSeconds(item.bc_start) < 600)
            ) {
                const token = this.$store.state.auth.token
                axios
                    .post(
                        '/activity/start-stream',
                        {
                            station_slug: item.station_slug,
                            bc_id: item.bc_id,
                            ad_available:
                                localStorage.getItem('ad_available') == 'true',
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then(response => {
                        if (response.data['params'].show_ad) {
                            localStorage.setItem('last_ad_at', dayjs())
                            localStorage.setItem('ad_loaded', 0)
                            this.ad.modal.show = true
                            this.ad.response = response
                        } else {
                            this.nativeBridge(
                                response.data['fn'],
                                response.data['params']
                            )
                        }
                    })
                    .catch(e => {
                        this.handleAxiosError(e)
                    })
            } else {
                this.openBroadcastModal(item)
            }
        },
        startInSeconds(start) {
            return dayjs(start).diff(dayjs(), 'seconds')
        },
        startIn(start) {
            let cd = getCountdown(start)
            let t = 'in '
            if (cd[1] > 0) {
                if (cd[1] == 1) {
                    t += '1h'
                } else {
                    t += cd[1] + 'h'
                }
            }
            if (cd[2] > 0) {
                if (cd[1] > 0) {
                    t += ', '
                }
                if (cd[2] == 1) {
                    t += '1min'
                } else {
                    t += cd[2] + 'min'
                }
            }
            return t
        },
        remainingMinutes(end) {
            let cd = getCountdown(end)
            let t = 'noch '
            if (cd[1] > 0) {
                if (cd[1] == 1) {
                    t += '1h'
                } else {
                    t += cd[1] + 'h'
                }
            }
            if (cd[2] > 0) {
                if (cd[1] > 0) {
                    t += ', '
                }
                if (cd[2] == 1) {
                    t += '1min'
                } else {
                    t += cd[2] + 'min'
                }
            }
            return t
        },
        remainingMinutesProgress(start, end) {
            return (
                100 -
                (dayjs(end).diff(dayjs(), 'minutes') /
                    dayjs(end).diff(dayjs(start), 'minutes')) *
                    100 +
                '%'
            )
        },
    },
}
</script>
