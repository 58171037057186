<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-else>
            <div class="input-group my-2">
                <input
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Durchsuchen..."
                    @keyup="search.keyword = $event.target.value"
                    :value="search.keyword"
                />
                <div
                    class="input-group-append"
                    v-if="search.keyword.length > 0"
                >
                    <button
                        class="btn btn-secondary"
                        type="button"
                        @click="search.keyword = ''"
                    >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </button>
                </div>
            </div>
            <div v-if="search.loading">
                <b-spinner class="my-4 mx-2" label="Laden..."></b-spinner>
            </div>
            <div v-if="!search.loading && search.keyword.length >= 1">
                <div class="text-left" v-if="search.keyword.length >= 1">
                    <span v-if="searchResults.length == 0"
                        >Keine Ergebnisse</span
                    >
                    <span v-if="searchResults.length == 1">1 Ergebniss</span>
                    <span
                        v-if="
                            searchResults.length >= 2 &&
                                searchResults.length < 49
                        "
                        >{{ searchResults.length }} Ergebnisse</span
                    >
                    <span v-if="searchResults.length == 50"
                        >über {{ searchResults.length }} Ergebnisse</span
                    >
                </div>

                <div v-for="(item, index) in searchResults" :key="'s-' + index">
                    <broadcast :layout="BROADCAST_LAYOUT_SEARCH" :item="item" />
                </div>
            </div>
            <div v-if="search.keyword.length < 1 && !$route.query.only_search">
                <div class="days d-flex py-2">
                    <div
                        v-for="(item, index) in days"
                        :key="'date-' + index"
                        class="shadow-sm py-1 px-3 mx-1 bg-white text-center"
                        :class="{
                            'text-primary': item.isToday,
                            'border border-primary':
                                item.date.format('YYYY-MM-DD') == dateQuery ||
                                (dateQuery === null && index == 0),
                        }"
                        @click="navigateDate(item.date.format('YYYY-MM-DD'))"
                    >
                        <small class="text-muted">
                            <span
                                :class="{
                                    'text-danger':
                                        item.date.format('dd') == 'Sa' ||
                                        item.date.format('dd') == 'So',
                                }"
                                >{{ item.date.format('dd') }}</span
                            >
                        </small>
                        <br />
                        <h4 class="m-0 font-weight-bolder">
                            {{ item.date.format('D') }}
                        </h4>
                        <small v-if="item.isToday">heute</small>
                    </div>
                </div>
                <div class="header bg-light" id="myHeader">
                    <div class="stations d-flex py-1">
                        <div
                            class="shadow-sm p-1 mx-1 bg-white d-flex justify-content-center"
                            v-for="(item, index) in pDayFromNow"
                            :key="'station-' + index"
                            :class="{
                                'border border-primary':
                                    item.slug == stationQuery ||
                                    (stationQuery === null && index == 0),
                            }"
                            @click="navigateStation(item)"
                        >
                            <img
                                :src="
                                    'images/senderlogos/live_ic_tab_' +
                                        item.slug +
                                        '.png'
                                "
                                style="max-width: 60px"
                            />
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div v-if="loading">
                        <b-spinner class="m-2" label="Laden..."></b-spinner>
                    </div>
                    <div
                        v-for="(item, index) in activeStation.sections"
                        :key="'range-' + index"
                        v-else
                    >
                        <h5
                            class="ml-1 mb-2 mt-4 font-weight-bolder"
                            v-if="item.data.length"
                        >
                            {{ item.label }}
                        </h5>
                        <div
                            v-for="(item2, index2) in item.data"
                            :key="'p-' + index2"
                        >
                            <broadcast
                                :layout="BROADCAST_LAYOUT_PROGRAM"
                                :item="item2"
                                :c="item2.c"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {
            loading: false,
            search: {
                keyword: '',
                items: [],
                loading: false,
                request: null,
            },
            selectedStation: null,
            selectedDate: null,
        }
    },
    watch: {
        'search.keyword': {
            handler() {
                if (this.search.loading) this.cancelRequest()
                if (this.search.keyword.length) {
                    this.search.loading = true
                    this.debSearch()
                } else {
                    this.$store.commit('program/UPDATE_PROP', {
                        prop: 'search',
                        value: [],
                    })
                }
            },
        },
        $route: {
            handler() {
                this.search.keyword = ''
            },
        },
    },
    computed: {
        ...mapGetters('program', {
            days: 'dates',
        }),
        ...mapGetters('program', {
            stations: 'pStations',
        }),
        ...mapGetters('program', {
            pDayFromNow: 'pDay',
        }),
        ...mapGetters('program', {
            searchResults: 'search',
        }),
        activeStation() {
            if (this.pDayFromNow.length) {
                return this.pDayFromNow.find((item, index) => {
                    return (
                        item.slug == this.stationQuery ||
                        (index == 0 && this.stationQuery == null)
                    )
                })
            } else {
                return {}
            }
        },
        dateQuery() {
            //return this.$route.query.date ? this.$route.query.date : null
            return this.selectedDate ? this.selectedDate : null
        },
        stationQuery() {
            //return this.$route.query.station ? this.$route.query.station : null
            return this.selectedStation ? this.selectedStation.slug : null
        },
        anyDataReady() {
            return (
                this.$store.state.program.init && this.$store.state.series.init
            )
        },
    },
    methods: {
        onKeyUp() {
            if (this.search.loading) this.cancelRequest()
            if (this.search.keyword.length >= 2) {
                this.debSearch()
            } else {
                this.searchResults = []
            }
        },
        navigateDate(value) {
            /*
                if (this.dateQuery != value) {
                    this.loading = true
                    let query = {
                        date: value,
                    }
                    if (this.stationQuery) {
                        query.station = this.stationQuery
                    }
                    query.api_token = this.$route.query.api_token
                    this.$router.push({ path: 'program', query })
                    this.fetchProgram()
                }
                */
            this.selectedDate = value
            this.fetchProgram()
        },
        navigateStation(item) {
            /*
                if (this.stationQuery != value) {
                    let query = {
                        station: value,
                    }
                    if (this.dateQuery) {
                        query.date = this.dateQuery
                    }
                    query.api_token = this.$route.query.api_token
                    this.$router.push({ path: 'program', query })
                }*/
            this.selectedStation = item
        },
        cancelRequest() {
            this.search.request.cancel()
        },
        searchProgram() {
            const axiosSource = axios.CancelToken.source()
            this.search.request = { cancel: axiosSource.cancel }
            this.search.loading = true
            this.$store
                .dispatch('program/search', {
                    data: {
                        keyword: this.search.keyword,
                    },
                    options: {
                        cancelToken: axiosSource.token,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.search.loading = false
                })
        },
        fetchProgram() {
            this.loading = true
            this.$store
                .dispatch('program/fetch', {
                    data: {
                        station: this.stationQuery,
                        date: this.dateQuery,
                    },
                })
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
        fetchBookmarks() {
            this.$store
                .dispatch('bookmarks/fetch')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {})
        },
    },
    created() {
        window.onscroll = () => {
            let header = document.getElementById('myHeader')
            if (header) {
                let sticky = header.offsetTop
                if (window.pageYOffset > sticky) {
                    header.classList.add('sticky')
                } else {
                    header.classList.remove('sticky')
                }
            }
        }
        this.debSearch = _.debounce(this.searchProgram, 250)
        //this.fetchProgram()
        //this.fetchBookmarks()
    },
}
</script>
