import Vue from 'vue'

const state = {
    search: [],
    all: [],
    first: {},
    init: false,
}
const getters = {
    search: (state, getters, rootState, rootGetters) => {
        if (state.search) {
            return state.search
        } else return []
    },
    series: (state, getters, rootState, rootGetters) => {
        if (state.all) {
            return state.all
        } else return []
    },
    broadcasts: (state, getters, rootState, rootGetters) => {
        if (state.first.broadcasts) {
            return state.first.broadcasts
        } else return []
    },
    first: (state, getters, rootState, rootGetters) => {
        if (Object.keys(state.first).length) {
            return state.first
        } else return {}
    },
}
const actions = {
    async search(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            const query =
                '?api_token=' + token + '&term=' + payload.data.keyword

            axios
                .get('/series/search' + query, {
                    cancelToken: payload.options.cancelToken,
                })
                .then(response => {
                    context.commit('SET_SEARCH_RESULTS', response.data)
                    resolve()
                })
                .catch(e => {
                    if (axios.isCancel(e)) {
                        console.log('search canceled')
                        resolve()
                    } else {
                        reject(e)
                    }
                })
        })
    },
    async show(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            const query = '?api_token=' + token

            axios
                .get('/series/' + payload.data.series_id + query)
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'first',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async update(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/series/' + payload.data.id, payload.data.update, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    const idx = context.state.all.findIndex(
                        item => item.series_id == response.data.series_id
                    )
                    Object.keys(payload.data.update).forEach(item => {
                        Vue.set(
                            context.state.all[idx],
                            item,
                            response.data[item]
                        )
                    })

                    //context.state.all.splice(idx, 1, response.data)
                    //console.log(context.state.all)
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async fetch(context, payload) {
        let query = '?api_token=' + context.rootState.auth.token
        if (payload.broadcasts) {
            query += '&broadcasts=1'
        }

        //const qs = query.forEach(item => item.)
        return new Promise((resolve, reject) => {
            axios
                .get('/series' + query)
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'all',
                        value: response.data,
                    })
                    context.commit('UPDATE_PROP', {
                        prop: 'init',
                        value: true,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    sync(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .post('/series', payload.data, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'all',
                        value: response.data,
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async destroy(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .delete('/bookmarks/' + payload.data.id, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('REMOVE_SERIES', payload.data)
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}
const mutations = {
    SET_SEARCH_RESULTS(state, search) {
        Vue.set(state, 'search', search)
    },
    SET_SERIES(state, series) {
        Vue.set(state, 'all', series)
    },
    REMOVE_SERIES(state, series) {
        state.all.splice(state.all.indexOf(series), 1)
    },
    UPDATE_PROP(state, payload) {
        state[payload.prop] = payload.value
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
