import Vue from 'vue'

const state = {
    settings: {
        wifi_only: false,
    },
    stations: [],
}
const getters = {}
const actions = {
    async fetchSettings(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .get('/user/settings?api_token=' + token)
                .then(response => {
                    context.state.settings.wifi_only = !!response.data.wifi_only;
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },    
    async fetchStations(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .get('/user/station?api_token=' + token)
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'stations',
                        value: response.data
                    })
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },    
    async updateSettings(context) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/user/settings?api_token=' + token, context.state.settings, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(response => {
                    context.state.settings.wifi_only = !!response.data.wifi_only;
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },  
    async updateStations(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .put('/user/station?api_token=' + token, payload.data, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                .then(response => {
                    context.commit('UPDATE_PROP', {
                        prop: 'stations',
                        value: response.data
                    })                    
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },         
}
const mutations = {
    UPDATE_PROP(state, payload) {
        state[payload.prop] = payload.value;
    }    
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
