import Vue from 'vue'
import Vuex from 'vuex'
import program from './modules/program'
import bookmarks from './modules/bookmarks'
import auth from './modules/auth'
import user from './modules/user'
import series from './modules/series'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        program,
        bookmarks,
        auth,
        user,
        series,
    },
})
