<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-if="anyDataReady">
            <h5 class="page-title">Mein Programm</h5>
            <p class="text-muted" v-if="!future.length">
                Es wurden keine Sendungen als germerkt markiert. Sie markieren
                eine Sendung, indem Sie in der Programmansicht auf den
                <font-awesome-icon
                    :icon="['far', 'star']"
                    class="text-primary"
                />
                tippen. Sie werden dann 5 Minuten vor Sendungsbeginn
                benachrichtigt.
            </p>
            <p class="text-muted" v-if="future.length">
                Sie werden 5 Minuten vor Sendungsbeginn benachrichtigt.
            </p>
            <div v-for="(item, index) in future" :key="'f-' + index">
                <h5
                    class="font-weight-bold my-3"
                    v-if="dayjs(item.date).isSame(new Date(), 'day')"
                >
                    Heute ({{ dayjs(item.date).format('DD.MM.YYYY') }})
                </h5>
                <h5
                    class="font-weight-bold my-3"
                    v-if="!dayjs(item.date).isSame(new Date(), 'day')"
                >
                    {{ dayjs(item.date).format('dd, DD.MM.YYYY') }}
                </h5>
                <broadcast
                    v-for="(item2, index2) in item.items"
                    :key="'f2' + index2"
                    :layout="3"
                    :item="item2"
                    :c="item2.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'

export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {
            dayjs,
            loading: true,
            show: false,
        }
    },
    computed: {
        ...mapGetters('bookmarks', {
            past: 'groupedByDatePast',
        }),
        ...mapGetters('bookmarks', {
            future: 'groupedByDateFuture',
        }),
        anyDataReady() {
            return (
                //this.$store.state.program.init &&
                this.$store.state.bookmarks.init
            )
        },
        sum() {
            return _.reduce(
                this.past,
                function(sum, n) {
                    return sum + n.items.length
                },
                0
            )
        },
    },
    methods: {
        fetchBookmarks() {
            this.loading = true
            this.$store
                .dispatch('bookmarks/fetch')
                .catch(e => {
                    this.handleAxiosError(e)
                })
                .then(() => {
                    this.loading = false
                })
        },
    },
    created() {},
}
</script>
