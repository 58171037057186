<template>
    <div>
        <div v-if="!anyDataReady">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
        </div>
        <div v-if="anyDataReady">
            <div
                class="shadow bg-white shadow p-3 rating"
                v-if="banner.rating.show"
            >
                <h1 class="h4 font-weight-bold mb-2">Gefällt dir die App?</h1>
                <div class="d-flex">
                    <button
                        type="button"
                        class="btn btn-outline-primary mr-2"
                        style="width: 120px"
                        @click="bannerRatingActionA()"
                    >
                        Ja
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        style="width: 120px"
                        @click="bannerRatingActionB()"
                    >
                        Nein
                    </button>
                </div>
            </div>
            <div
                class="alert alert-dismissible shadow bg-white my-3"
                v-if="banner.rating.action.a"
            >
                <button
                    type="button"
                    class="close"
                    @click="bannerRatingActionClose()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="h4 font-weight-bold mb-2">
                    <font-awesome-icon
                        :icon="['fas', 'star-half-alt']"
                        class="mr-1"
                    />
                    Bitte bewerte uns
                </h1>
                <p class="mb-1">
                    Wir würden uns sehr freuen wenn du dir kurz Zeit nimmst und
                    uns 5 Sterne in Google Play geben würdest.
                </p>
                <button
                    type="button"
                    class="btn btn-block btn-outline-primary"
                    @click="bannerRatingActionAA()"
                >
                    Jetzt Bewertung abgeben
                </button>
            </div>
            <div
                class="shadow bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('live')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'play-circle']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">Jetzt Live</h1>
                    <span class="text-muted mb-0">Was läuft gerade?</span>
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="shadow bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('p2015')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'magic']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">20:15</h1>
                    <span class="text-muted mb-0"
                        >Was läuft zur Prime Time?</span
                    >
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="shadow bg-white p-3 my-2 d-flex align-items-center"
                @click="cc('p2200')"
            >
                <div style="width: 45px">
                    <font-awesome-icon
                        class="h2 mb-0 text-primary"
                        :icon="['fas', 'cloud-moon']"
                    />
                </div>
                <div>
                    <h1 class="h4 font-weight-bold mb-0">22:00</h1>
                    <span class="text-muted mb-0">Was läuft heute Nacht?</span>
                </div>
                <div class="pl-4 flex-fill text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div v-if="todaysBookmarks.length">
                <h1 class="h5 text-white mt-4 mb-2">
                    Für heute gemerkte Sendungen
                </h1>
                <div
                    v-for="(item, index) in todaysBookmarks"
                    :key="'f-' + index"
                >
                    <broadcast
                        v-for="(item2, index2) in item.items"
                        :key="'f2' + index2"
                        :layout="3"
                        :item="item2"
                        :c="item2.c"
                    />
                </div>
                <router-link
                    class="btn btn-light my-2"
                    :to="{ name: 'bookmarks' }"
                    exact
                    >Alle Tage anzeigen
                </router-link>
            </div>
            <div v-if="1 == 1">
                <h1 class="h5 font-weight-bold mt-4 mb-2">
                    Das könnte dich interessieren
                </h1>
                <div
                    v-for="(item, index) in todaysBookmarks"
                    :key="'f-' + index"
                >
                    <broadcast
                        v-for="(item2, index2) in item.items"
                        :key="'f2' + index2"
                        :layout="3"
                        :item="item2"
                        :c="item2.c"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Broadcast from '../components/Broadcast'
import axios from 'axios'
export default {
    components: {
        Broadcast,
    },
    data: function() {
        return {
            dayjs,
            banner: {
                primeVideo: {
                    show: false,
                    action: {
                        a: false,
                    },
                },
                rating: {
                    show: false,
                    action: {
                        a: false,
                        b: false,
                    },
                },
                dkms: {
                    show: false,
                    action: {
                        a: false,
                    },
                },
            },

            loading: true,
        }
    },
    computed: {
        ...mapGetters('bookmarks', {
            future: 'groupedByDateFuture',
        }),
        anyDataReady() {
            return (
                this.$store.state.program.init &&
                this.$store.state.bookmarks.init
            )
        },
        todaysBookmarks() {
            return this.future.filter(item =>
                dayjs(item.date).isSame(new Date(), 'day')
            )
        },
    },
    methods: {
        cc(val) {
            this.$router.push({
                name: val,
                query: {
                    history_back: 1,
                },
            })
        },
        bannerDkmsActionClose() {
            this.banner.dkms.show = false
            localStorage.setItem('banner-dkms-hide', true)
            axios
                .post(
                    '/activity/banner/dkms',
                    { v: 'close' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },
        bannerDkmsActionA() {
            this.banner.dkms.show = false
            this.banner.dkms.action.a = true
            axios
                .post(
                    '/activity/banner/dkms',
                    { v: 'click' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
            this.nativeBridge('openUrl', {
                url: 'https://www.dkms.de/de/spender-werden',
                mode: 'chrome',
            })
        },

        bannerPrimeVideoActionA() {
            this.banner.primeVideo.action.a = true

            axios
                .post(
                    '/activity/banner/prime-video',
                    { v: 'click' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
            this.nativeBridge('openUrl', {
                url: 'http://www.amazon.de/gp/video/primesignup?tag=gagatv-21',
                mode: 'chrome',
            })
        },
        bannerPrimeVideoActionClose() {
            this.banner.primeVideo.show = false
            localStorage.setItem('banner-prime-video-hide', true)
            axios
                .post(
                    '/activity/banner/prime-video',
                    { v: 'close' },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.auth.token}`,
                        },
                    }
                )
                .then(response => {})
                .catch(e => {
                    this.handleAxiosError(e)
                })
        },

        bannerRatingActionA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = true
        },
        bannerRatingActionB() {
            this.banner.rating.show = false
            this.banner.rating.action.b = true
            localStorage.setItem('banner-rating-hide', true)
            this.nativeBridge(
                'openUrl',
                'https://v1.live-tv-kostenlos.com/contact?type=rating'
            )
        },
        bannerRatingActionClose() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
        },
        bannerRatingActionAA() {
            this.banner.rating.show = false
            this.banner.rating.action.a = false
            this.banner.rating.action.b = false
            localStorage.setItem('banner-rating-hide', true)
            this.nativeBridge('openPlayStore', 'com.live.tv.kostenlos')
        },
    },
    created() {
        if (localStorage.getItem('banner-rating-hide') == null) {
            this.banner.rating.show = true
        } else {
            if (
                localStorage.getItem('banner-prime-video-hide') == null &&
                localStorage.getItem('app_version') == 6
            ) {
                //this.banner.primeVideo.show = true
            } else {
                if (localStorage.getItem('banner-dkms-hide') == null) {
                    //this.banner.dkms.show = true
                }
            }
        }
    },
}
</script>
