import Vue from 'vue'
import router from '../../router'

const state = {
    all: [],
    init: false,
    disabled: false,
    loading: false,
    fromCache: true,
    c: 0,
}
const getters = {
    bookmarks: (state, getters, rootState, rootGetters) => {
        console.log('bookmarks getter')
        const djs = dayjs()
        if (state.all) {
            return state.all.map(item => {
                item.running = djs.isBetween(item.bc_start, item.bc_end)
                item.finished = djs.isAfter(item.bc_end)
                item.c = state.c
                return item
            })
        } else return []
    },
    groupedByDate: (state, getters, rootState, rootGetters) => {
        console.log('groupedByDate getter')
        if (getters.bookmarks) {
            const groups = getters.bookmarks.reduce((groups, item) => {
                const date = dayjs(item.bc_start).format('YYYY-MM-DD')
                if (!groups[date]) {
                    groups[date] = []
                }
                groups[date].push(item)
                return groups
            }, {})

            let groupArrays = Object.keys(groups).map(date => {
                return {
                    date,
                    items: _.sortBy(groups[date], [
                        function(o) {
                            return o.bc_start
                        },
                    ]),
                }
            })

            return _.sortBy(groupArrays, [
                function(o) {
                    return o.date
                },
            ])
        } else return []
    },
    groupedByDatePast: (state, getters, rootState, rootGetters) => {
        return _.orderBy(
            _.filter(
                _.map(getters.groupedByDate, item => {
                    return {
                        date: item.date,
                        items: _.filter(item.items, e =>
                            dayjs(e.bc_end).isBefore(dayjs())
                        ),
                    }
                }),
                e => e.items.length
            ),
            ['date'],
            ['desc']
        )
    },
    groupedByDateFuture: (state, getters, rootState, rootGetters) => {
        return _.orderBy(
            _.filter(
                _.map(getters.groupedByDate, item => {
                    return {
                        date: item.date,
                        items: _.filter(item.items, e =>
                            dayjs().isBefore(e.bc_end)
                        ),
                    }
                }),
                e => e.items.length
            ),
            ['date'],
            ['asc']
        )
    },
}
const actions = {
    async store(context, payload) {
        console.log(payload.data)
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .post('/bookmark', payload.data, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('SET_BOOKMARKS', response.data)
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
    async fetch(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            let pfBookmarks = JSON.parse(
                localStorage.getItem('__prefetched_bookmarks')
            )
            let requestRequired = true
            if (
                pfBookmarks !== null &&
                dayjs().isBefore(pfBookmarks.expires_at)
            ) {
                requestRequired = false
            }
            const forceUpdate = router.currentRoute.query.update
            if (requestRequired || context.state.init || 1) {
                axios
                    .get('/bookmark?api_token=' + token)
                    .then(response => {
                        context.commit('SET_BOOKMARKS', response.data)
                        context.commit('UPDATE_PROP', {
                            prop: 'fromCache',
                            value: false,
                        })
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    })
            } else {
                console.log('loading bookmarks from local storage...')
                context.commit('SET_BOOKMARKS', pfBookmarks.data.bookmarks)
            }
        })
    },
    async destroy(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            axios
                .delete('/bookmark/' + payload.data.bc_id, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then(response => {
                    context.commit('REMOVE_BOOKMARK', payload.data)
                    resolve()
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}
const mutations = {
    SET_BOOKMARKS(state, bookmarks) {
        Vue.set(state, 'all', bookmarks)
        Vue.set(state, 'init', true)
    },
    PUSH_BOOKMARK(state, bookmark) {
        state.all.push(bookmark)
    },
    REMOVE_BOOKMARK(state, bookmark) {
        state.all.splice(state.all.indexOf(bookmark), 1)
    },
    INCREMENT_COUNTER(state) {
        console.log('increment')
        Vue.set(state, 'c', state.c + 1)
    },
    UPDATE_PROP(state, payload) {
        state[payload.prop] = payload.value
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
