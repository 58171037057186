import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Today from './../views/Today'
import Live from './../views/Live'
import P2015 from './../views/P2015'
import P2200 from './../views/P2200'
import Program from './../views/Program.vue'
import Bookmarks from './../views/Bookmarks.vue'
import Tv from './../views/Tv.vue'
import Settings from './../views/Settings.vue'

const routes = [
    {
        path: '/today',
        name: 'today',
        component: Today,
        alias: '/',
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
    },
    {
        path: '/2015',
        name: 'p2015',
        component: P2015,
    },
    {
        path: '/2200',
        name: 'p2200',
        component: P2200,
    },
    {
        path: '/program',
        name: 'program',
        component: Program,
    },
    {
        path: '/program2',
        name: 'program2',
        component: Program,
    },
    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: Bookmarks,
    },
    {
        path: '/tv',
        name: 'tv',
        component: Tv,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
    },
]
const router = new VueRouter({
    scrollBehavior(to, from, savedPostion) {
        if (to.query !== undefined && to.query.station !== undefined) {
            return savedPostion
        }
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: '/',
    routes,
})

export default router
